import { RouterModule, Routes } from "@angular/router";

import { PreloadingStrategy, PreloadAllModules } from "@angular/router";
import { LoginActivateGuardService } from "./guards/login-activate-guard.service";
import { LogoutActivateGuardService } from "./guards/logout-activate-guard.service";

const app_routes: Routes = [
    { path: 'auth', loadChildren: './auth/auth.module#AuthModule', canActivate: [LogoutActivateGuardService] },
    { path: 'citas', loadChildren: './dates/dates.module#DatesModule', canActivate: [LoginActivateGuardService] },
    { path: 'familias', loadChildren: './familia/familia.module#FamiliaModule', canActivate: [LoginActivateGuardService] },
    { path: 'personas', loadChildren: './persona/persona.module#PersonaModule', canActivate: [LoginActivateGuardService] },
    { path: 'inventario', loadChildren: './inventario/inventario.module#InventarioModule', canActivate: [LoginActivateGuardService] },
    { path: 'documentos', loadChildren: './documents/documents.module#DocumentsModule', canActivate: [LoginActivateGuardService] },
    { path: '', pathMatch: 'full', redirectTo: 'auth' },
    { path: '**', redirectTo: 'auth' }
];

export const APP_ROUTING = RouterModule.forRoot(app_routes, {preloadingStrategy: PreloadAllModules});
