import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './auth/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  links = ['citas', 'personas', 'familias', 'inventario', 'documentos'];
  active: any;
  isLogged: boolean;

  constructor( public router: Router, private authService: AuthService ) {
    setTimeout(() => {
      this.active = this.router.url.split('/')[1];
    }, 200);
    this.authService.$loginEmitter.subscribe(
      res => this.isLogged = res
    )
  }

  logout() {
    this.authService.logout();
    this.isLogged = false;
    this.router.navigate(['/auth']);
  }
}
