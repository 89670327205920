import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { SERVER } from '../../constants';
import { map, catchError } from 'rxjs/operators';
import { Iresponse } from 'src/app/interfaces/iresponse';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  @Output() $loginEmitter = new EventEmitter<boolean>();
  logged: boolean;
  admin: boolean;

  constructor( private http: HttpClient ) { }

  login(name, password): Observable<boolean> {
    let user = { user: name, password: password };

    return this.http.post<{ok: boolean, token: string}>(`${SERVER}auth/login`, user).pipe(
      map(
        response => {
          if (response.ok) {
            this.logged = true;
            this.$loginEmitter.emit(true);
            localStorage.setItem('token', response.token)
            return true;
          }
          return false;
        }
      )
    )
  }

  // register(data): Observable<boolean> {
  //   console.log(data);
  //   return this.http.post<{ok: boolean, token: string, error?: string}>(`${SERVER}auth/registro`, data).pipe(
  //     map( response => {
  //       if (response.ok) {
  //         console.log('response:',response);
  //         this.logged = true;
  //         this.$loginEmitter.emit(true);
  //         localStorage.setItem('token', response.token);
  //         return true;
  //       }
  //       throw response.error;
  //     })
  //   )
  // }

  logout() {
    localStorage.removeItem('token');
    this.logged = false;
    this.admin = false;
    this.$loginEmitter.emit(false);
  }

  isLogged(): Observable<boolean> {
    if (localStorage.getItem('token')) {
      return this.http.get<{ok: boolean}>(`${SERVER}auth/token`).pipe(
        map( response => {
          if (response.ok) {
            this.logged = true;
            this.$loginEmitter.emit(true);
            return true;
          }
          this.$loginEmitter.emit(false);
          return false;
        }),
        catchError( () => of(false))
        )
      } else {
        this.$loginEmitter.emit(false);
        return of(false);
    }
  }

  isAdmin(): Observable<boolean> {
    if (this.admin) return of(true)
    else if (this.logged || localStorage.getItem('token')) {
      return this.http.get(`${SERVER}auth/admin`).pipe(
        map( (res: Iresponse) => {
          if (res.ok) {
            this.admin = true;
            return true;
          } else {
            return false
          }
        }
        ),
        catchError( err => of(false))
      )
    } else return of(false);
  }
}
