import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth/services/auth.service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginActivateGuardService implements CanActivate {

  constructor( private authService: AuthService, private router: Router ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isLogged().pipe(
      map( res =>
        res ? true : ( this.router.navigate(['/auth']) && false )
      ),
      catchError( () => {
        this.router.navigate(['/auth']);
        return of(false);
      })
    )
  }
}
